import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import PrimaryBtn from "../components/PrimaryBtn";
import { BASE_URL } from "../utils/constants/applicationConstants";
import { SHOW_TOAST } from "../store/actions/toastAction";
import { LOADING_SCREEN, SNACKBAR_AUTO_HIDE_DURATION } from "../utils/constants/constants";
import VideoLecture from "./VideoLecture";
import LoadingScreen from "../components/LoadingScreen";
import Design from "./VideoLectures.module.css";
import EmptyVideoLecture from "./EmptyVideoLecture";

function VideoLectures(props) {
  const { confirmAction, action, userId, openSnackbar } = props;
  const [videoLectures, setVideoLectures] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/video/`)
      .then((res) => {
        setVideoLectures(res.data.data);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  useEffect(() => {
    if (props.confirmAction && props.action === "delete") {
      axios
        .delete(`${BASE_URL}/video/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            openSnackbar(
              "Tutorial videos deleted successfully",
              "success",
              true
            );
            setTimeout(() => {
              window.location.reload();
            }, SNACKBAR_AUTO_HIDE_DURATION);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [confirmAction, action, userId]);

  setTimeout(() => {
    setLoadingScreen(false);
  }, LOADING_SCREEN);

  const videoLecture = videoLectures.map((videoLecture) => {
    return (
      <div className={Design.col} key={videoLecture.id}>
        <VideoLecture videoLecture={videoLecture} />
      </div>
    );
  });

  return (
    <div>
      {loadingScreen ? (
        <LoadingScreen />
      ) : (
        <div>
          <div className="headerSection">
            <Header title={"Video Lectures"} />
            <NavLink to={"/create/video"}>
              <PrimaryBtn title={"+ Create New"} />
            </NavLink>
          </div>
          <div className="topMargin">
            <div className="container">
              <div style={{display: videoLectures.length === 0 ? "none" : "block"}}>
                <div className={Design.row}>{videoLecture}</div>
              </div>
              <div style={{display: videoLectures.length === 0 ? "block" : "none"}}>
                <EmptyVideoLecture/>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

const mapStateToProps = (state) => {
  return {
    userId: state.popupReducer.userId,
    action: state.popupReducer.action,
    confirmAction: state.popupReducer.confirmAction,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoLectures);
