import React from "react";
import { Avatar, List } from "antd";
import Header from "../components/Header";

export default function Notifications() {

  const data = [
    {
      title: 'Notification Title 1',
    },
    {
      title: 'Notification Title 2',
    },
    {
      title: 'Notification Title 3',
    },
    {
      title: 'Notification Title 4',
    },
  ];

  return (
    <div>
      <div className="headerSection">
        <Header title={"Notifications"} />
      </div>
      <div className="topMargin">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                  />
                }
                title={<a href="https://ant.design">{item.title}</a>}
                description="lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi"
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
