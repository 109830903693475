import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Header from "../components/Header";
import PrimaryBtn from "../components/PrimaryBtn";
import Design from "./AddAppointments.module.css";
import { SHOW_TOAST } from "../store/actions/toastAction";
import { SNACKBAR_AUTO_HIDE_DURATION } from "../utils/constants/constants";
import {
  emailValidator,
  containsOnlyNumbers,
} from "../utils/functions/validators";
import { toFindDayOfWeek, getCurrentDate } from "../utils/functions/convertors";
import axios from "axios";
import { BASE_URL } from "../utils/constants/applicationConstants";

function AddAppointments(props) {
  const navigate = useNavigate();
  const [clinics, setClinics] = useState([]);
  const [clinicName, setClinicName] = useState();
  const [clinicId, setClinicId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [insurance, setInsurance] = useState("NO");
  const [insuranceBoolen, setInsuranceBoolen] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [appointmentDate, setAppointmentDate] = useState();
  const [workingDays, setWorkingDays] = useState();
  const [loading, setLoading] = useState(false);
  const [disableClinic, setdisableClinic] = useState(false);
  const [disableGender, setdisableGender] = useState(false);
  const [appointmentLink, setAppointmentLink] = useState();

  const onSetInsurance = (event) => {
    setInsurance(event.target.value);
    if (event.target.value === "YES") {
      setInsuranceBoolen(true);
    } else if (event.target.value === "NO") {
      setInsuranceBoolen(false);
    }
  };

  const onSelectClinic = (event) => {
    let workingDayHtml = "Working Days: ";
    const selectedElement = document.getElementById("clinicList");
    const selectedOption =
      selectedElement.options[selectedElement.selectedIndex];
    setClinicId(selectedOption.value);
    setClinicName(selectedOption.textContent);
    setdisableClinic(true);

    clinics.map((clinic)=>{
      if(clinic.id === selectedOption.value){
        setAppointmentLink(clinic.link)
      }
    })

    axios
      .get(`${BASE_URL}/day/clinic/{clinic_id}?id=${selectedOption.value}`)
      .then((res) => {
        const data = res.data.data;
        data.map((item, index) => {
          if (data.length - 1 === index) {
            workingDayHtml += item.day;
          } else {
            workingDayHtml += item.day + ", ";
          }
        });
        setWorkingDays(workingDayHtml);
      })
      .catch((err) => console.log(err));
  };

  const onHandleValidation = () => {
    if (
      !clinicName &&
      clinicName !== "Please enter all fields" &&
      !name &&
      !email &&
      !phone &&
      !age &&
      !appointmentDate &&
      !gender &&
      gender !== "Gender"
    ) {
      props.openSnackbar("Please enter all fields", "error", true);
      setLoading(false);
      return false;
    } else if (!clinicName && clinicName !== "Select Clinic") {
      props.openSnackbar("Please select clinic", "error", true);
      setLoading(false);
      return false;
    } else if (!name) {
      props.openSnackbar("Please enter name", "error", true);
      setLoading(false);
      return false;
    } else if (!email) {
      props.openSnackbar("Please enter email", "error", true);
      setLoading(false);
      return false;
    } else if (!emailValidator(email)) {
      props.openSnackbar("Please enter valid email", "error", true);
      setLoading(false);
      return false;
    } else if (!phone) {
      props.openSnackbar("Please enter phone", "error", true);
      setLoading(false);
      return false;
    } else if (phone.length > 11) {
      props.openSnackbar("Please enter valid phone number", "error", true);
      setLoading(false);
      return false;
    } else if (!containsOnlyNumbers(phone)) {
      props.openSnackbar("Please enter valid phone number", "error", true);
      setLoading(false);
      return false;
    } else if (insurance === "YES" && !insuranceInfo) {
      props.openSnackbar("Please enter insurance info", "error", true);
      setLoading(false);
      return false;
    } else if (!age) {
      props.openSnackbar("Please enter age", "error", true);
      setLoading(false);
      return false;
    } else if (!containsOnlyNumbers(age)) {
      props.openSnackbar("Please enter valid age", "error", true);
      setLoading(false);
      return false;
    } else if (!appointmentDate) {
      props.openSnackbar("Please enter appointment date", "error", true);
      setLoading(false);
      return false;
    } else if (appointmentDate) {
      const dayofWeek = toFindDayOfWeek(appointmentDate);
      if (!workingDays.includes(dayofWeek)) {
        props.openSnackbar(
          "Clinic is not availbale on this date ",
          "error",
          true
        );
        setLoading(false);
        return false;
      }
    } else if (!gender && gender !== "Gender") {
      props.openSnackbar("Please select a valid gender", "error", true);
      setLoading(false);
      return false;
    }
    return true;
  };

  const onCreateAnAppointment = () => {
    setLoading(true);
    if (onHandleValidation()) {
      const data = {
        age: age,
        clinic: clinicName,
        clinic_id: clinicId,
        date: appointmentDate,
        email: email,
        gender: gender,
        name: name,
        phone: "+44" + phone,
        insurance: insuranceBoolen,
        authorisation: insuranceInfo,
      };

      axios
        .post(`${BASE_URL}/appointment/`, data)
        .then((res) => {
          props.openSnackbar(
            "Appointment created successfully",
            "success",
            true
          );
          setLoading(false);
          setTimeout(() => {
            navigate("/appointments");
          }, SNACKBAR_AUTO_HIDE_DURATION);
        })
        .catch((err) => {
          setLoading(false);
          props.openSnackbar("Something went wrong", "error", true);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/clinic/`)
      .then((res) => {
        setClinics(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <div className="headerSection">
        <Header title={"Create Appointment"} />
      </div>
      <div className="topMargin">
        <div className={Design.addAppointmentContainer}>
          <h5>Clinic Name</h5>
          <div style={{ width: "100%" }} className="textInput">
            <select
              id="clinicList"
              className={Design.selectorInput}
              onChange={onSelectClinic}
            >
              <option value={"Select Clinic"} disabled={disableClinic}>
                Select Clinic
              </option>
              {clinics.map((clinic) => {
                return <option value={clinic.id}>{clinic.name}</option>;
              })}
            </select>
          </div>
          <div className={Design.workingDaysAndLinkContainer}>
            {workingDays ? (
              <p id="clinicWorkingDays" className={Design.workingDays}>
                {workingDays}
              </p>
            ) : null}
            {/* { appointmentLink?(
            <a href={appointmentLink}>Book appointment on clinic website</a>) : null
            } */}
          </div>
          <h5>Patient Name</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Patient Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <h5>Email</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Patient Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <h5>Phone Number</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="+44"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <h5>Do you have a medical insurance?</h5>
          <div>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={insurance}
              onChange={onSetInsurance}
            >
              <FormControlLabel
                value="YES"
                checked={insurance === "YES"}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="NO"
                checked={insurance === "NO"}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </div>
          {insurance === "YES" ? (
            <div className={Design.insuranceAuthorisation}>
              <h5>If yes, then insert your insurance number</h5>
              <div className="textInput">
                <input
                  type="text"
                  placeholder="Insurance Info"
                  value={insuranceInfo}
                  onChange={(e) => setInsuranceInfo(e.target.value)}
                />
              </div>
            </div>
          ) : null}
          <div className={Design.ageDateGenderSection}>
            <div className={Design.ageDateGenderSectionCol}>
              <h5>Age</h5>
              <div className={Design.ageDateGenderSectionInput}>
                <input
                  type="text"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
            </div>

            <div className={Design.ageDateGenderSectionColSelector}>
              <h5>Gender</h5>
              <div className={Design.ageDateGenderSectionInput}>
                <select
                  className={Design.selectorInput}
                  onChange={(e) => {
                    setGender(e.target.value);
                    setdisableGender(true);
                  }}
                >
                  <option disabled={disableGender}>Gender</option>
                  <option value={"Female"}>Female</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Others"}>Others</option>
                </select>
              </div>
            </div>
          </div>

          <h5>Appointment Date</h5>
          <div className="textInput createAppointmentDate">
            <input
              type="date"
              min={getCurrentDate()}
              value={appointmentDate}
              onChange={(e) => setAppointmentDate(e.target.value)}
            />
          </div>

          <span onClick={onCreateAnAppointment}>
            <PrimaryBtn title={"Create"} loading={loading} />
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(AddAppointments);
