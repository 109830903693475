import React from 'react';
import { Tag } from 'antd';

export default function AppointmentStatus(props) {
  let color;
  if(props.status === "pending") {
    color = "gold";
  }else if(props.status === "confirmed") {
    color = "green";
  }else if(props.status === "canceled") {
    color = "volcano";
  }else if (props.status === "completed") {
    color = "blue";
  }else if (props.status === "rescheduled") {
    color = "geekblue";
  }else if (props.status === "rejected") {
    color = "red";
  }else if (props.status === "unavailable") {
    color = "purple";
  }
  return (
    <Tag style={{textTransform: "uppercase"}} color={color}>{props.status}</Tag>
  )
}
