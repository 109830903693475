import React from "react";
import { connect } from "react-redux";
import { Card } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import deleteIcon from "../images/delete.svg";
import editIcon from "../images/edit.svg";
import eyeIcon from "../images/eyeIcon.svg";
import Design from "./PatientLeaflets.module.css";
import { SHOW_POPUP } from "../store/actions/popUpActions";

const { Meta } = Card;

function PatientLeaflet(props) {
  const navigate = useNavigate();
  const { patientLeaflet } = props;

  const confirmDeleteClinic = () => {
    props.onOpenPopup(true, false, patientLeaflet.id, "delete");
  };

  const onEditPatientLeaflet = () =>{
    navigate(`/update/leaflets/${patientLeaflet.id}`)
  }

  return (
    <div className={Design.card}>
      <Card
        style={{
          width: "100%",
        }}
        cover={
          <NavLink to={`/info/leaflets/${patientLeaflet.id}`}>
            <img
              width={"99%"}
              height={150}
              style={{ objectFit: "cover", margin: "2px" }}
              alt="example"
              src={patientLeaflet.image}
            />
          </NavLink>
        }
        actions={[
          <img
            src={deleteIcon}
            alt="delete"
            key={"delete"}
            onClick={confirmDeleteClinic}
          />,
          <img src={editIcon} alt="edit" key={"edit"} onClick={onEditPatientLeaflet} />,
        ]}
      >
        <NavLink to={`/info/leaflets/${patientLeaflet.id}`}>
          <Meta
            title={patientLeaflet.title}
            description={patientLeaflet.desc}
          />
        </NavLink>
      </Card>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenPopup: (showPopup, confirmAction, userId, action) =>
      dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action }),
  };
};

export default connect(null, mapDispatchToProps)(PatientLeaflet);
