import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import React from 'react';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { THEME } from "./utils/custom/Theme";
import { ThemeProvider } from "@mui/material/styles";

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const firebaseConfig = {
  apiKey: "AIzaSyAxnwsyhgYZF7nLjfzqS5Whi1C92winvf0",
  authDomain: "neuroapp-london.firebaseapp.com",
  databaseURL: "https://neuroapp-london-default-rtdb.firebaseio.com",
  projectId: "neuroapp-london",
  storageBucket: "neuroapp-london.appspot.com",
  messagingSenderId: "351833534993",
  appId: "1:351833534993:web:4d588aa2f17819440e37b0",
  measurementId: "G-4Q4XMJ48YJ"
};


initializeApp(firebaseConfig);
// getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));

const app = (
  <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

root.render(<StrictMode>{app}</StrictMode>);

reportWebVitals();
