import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Table } from "antd";
import AppointmentStatus from "./AppointmentStatus";
import AppointmentDropdown from "./AppointmentDropdown";
import ReactLoading from "react-loading";
import Design from "./Appointments.module.css";
import { SHOW_TOAST } from "../store/actions/toastAction";
import { SHOW_POPUP } from "../store/actions/popUpActions";
import { BASE_URL } from "../utils/constants/applicationConstants";
import { SNACKBAR_AUTO_HIDE_DURATION } from "../utils/constants/constants";


const AppointmentTable = (props) => {
  const {
    confirmAction,
    action,
    openSnackbar,
    onOpenPopup,
    onCheckBulkDelete,
    tableLoading,
    availableClinics
  } = props;
  const { appointments } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const clinicNameFilter = [];

  availableClinics.forEach((clinic)=>{
    clinicNameFilter.push({
      text: clinic.name,
      value: clinic.name
    })
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      },
    },
    {
      title: "Patient Name",
      dataIndex: "name",
    },
    {
      title: "Insurance",
      dataIndex: "insurance",
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: "Male",
          value: "Male"
        },
        {
          text: "Female",
          value: "Female"
        },
        {
          text: "Others",
          value: "Others"
        }
      ],
      onFilter: (value, record) => record.gender.indexOf(value) === 0,
    },
    {
      title: "Contact",
      dataIndex: "phone",
    },
    {
      title: "Location",
      dataIndex: "clinic",
      key: "clinic",
      filters: clinicNameFilter,
      onFilter: (value, record) => record.clinic.indexOf(value) === 0,
    },
    {
      title: "Timeslot",
      dataIndex: "timeslot",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <AppointmentStatus status={status} />,
      filters: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Confirmed",
          value: "confirmed",
        },
        {
          text: "Rescheduled",
          value: "rescheduled",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Unavailable",
          value: "unavailable",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
        {
          text: "Canceled",
          value: "canceled",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "",
      render: (record) => <AppointmentDropdown record={record} />,
    },
  ];
  

  const data = [];

  appointments.forEach((appointment) => {
    const insurance = appointment.insurance ? appointment.authorisation : "N/A";
    const timeslot = appointment.rescheduled_slot? appointment.rescheduled_slot : (appointment.timeslot ? appointment.timeslot : "00:00");
    const rescheduledDate = appointment.rescheduled_date?  appointment.rescheduled_date : appointment.date;

    data.push({
      key: appointment.id,
      date: rescheduledDate,
      name: appointment.name,
      insurance: insurance,
      age: appointment.age,
      gender: appointment.gender,
      phone: appointment.phone,
      clinic: appointment.clinic,
      status: appointment.status,
      timeslot: timeslot,
    });
  });

  const onApproveBulkdeleteRows = () => {
    onCheckBulkDelete(true);
    onOpenPopup(true, false, "delete");
  };

  useEffect(() => {
    if (confirmAction && action === "delete" && selectedRowKeys.length !== 0) {
      let data = JSON.stringify(selectedRowKeys);

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${BASE_URL}/appointment/bulk_delete/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          openSnackbar("Appointments deleted successfully", "success", true);
          setTimeout(() => {
            window.location.reload();
          }, SNACKBAR_AUTO_HIDE_DURATION);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      confirmAction &&
      action === "delete" &&
      selectedRowKeys.length === 0
    ) {
      onCheckBulkDelete(false);
    } else if (
      !confirmAction &&
      action === "delete" &&
      selectedRowKeys.length !== 0
    ) {
      onCheckBulkDelete(false);
    }
  }, [confirmAction, action]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className={Design.appointmentTableContainer}>
      <div>
        {selectedRowKeys.length !== 0 ? (
          <div className={Design.selectedRowsToDelete}>
            <span className={Design.deleteRowsCount}>
              Selected rows: {selectedRowKeys.length}
            </span>
            <span>
              <button
                onClick={onApproveBulkdeleteRows}
                className={Design.deleteBtn}
              >
                Delete All
              </button>
            </span>
          </div>
        ) : null}
        <div className={Design.slidingTable}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>

      {tableLoading ? (
        <div className={Design.appointmentTableLoading}>
          <div className={Design.loadingScreen}>
            <div className={Design.loadingContainer}>
              <ReactLoading
                type={"spinningBubbles"}
                color={"#5B5EDB"}
                height={150}
                width={150}
              />
              <h1>LOADING...</h1>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
    onOpenPopup: (showPopup, confirmAction, action) =>
      dispatch({ type: SHOW_POPUP, showPopup, confirmAction, action }),
  };
};

const mapStateToProps = (state) => {
  return {
    action: state.popupReducer.action,
    confirmAction: state.popupReducer.confirmAction,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTable);
