import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { PRIMARY_COLOR } from "../../utils/custom/colorPalette";
import { BASE_URL } from "../../utils/constants/applicationConstants";
import { SHOW_TOAST } from "../../store/actions/toastAction";
import { SNACKBAR_AUTO_HIDE_DURATION } from "../../utils/constants/constants";

function CancelPopup(props) {
  const [appointmentInfo, setAppointmentInfo] = useState([]);
  const { handleClose, confirmAction, appointmentId, openSnackbar } = props;

  useEffect(() => {
    axios
      .get(`${BASE_URL}/appointment/${appointmentId}`)
      .then((response) => {
        setAppointmentInfo(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const cancelAppointment = () => {
    let url;
    let data;

    if (appointmentInfo.status === "rescheduled") {
      url = `/appointment/rescheduled/cancel/${appointmentId}`;
      data = {
        clinic: appointmentInfo.clinic,
        clinic_id: appointmentInfo.clinic_id,
        date: appointmentInfo.date,
        email: appointmentInfo.email,
        name: appointmentInfo.name,
        phone: appointmentInfo.phone,
        rescheduled_date: appointmentInfo.rescheduled_date,
        rescheduled_slot: appointmentInfo.rescheduled_slot,
        rescheduled_slot_id: appointmentInfo.rescheduled_slot_id,
        status: "canceled",
        timeslot: appointmentInfo.timeslot,
        timeslot_id: appointmentInfo.timeslot_id,
      };
    }else if(appointmentInfo.status === "pending"){
      url = `/appointment/pending/cancel/${appointmentId}`;
      data = {
        clinic: appointmentInfo.clinic,
        clinic_id: appointmentInfo.clinic_id,
        date: appointmentInfo.date,
        email: appointmentInfo.email,
        name: appointmentInfo.name,
        phone: appointmentInfo.phone,
        status: "canceled"
      }
    }else if(appointmentInfo.status === "confirmed"){
      url=`/appointment/scheduled/cancel/${appointmentId}`;
      data = {
        clinic: appointmentInfo.clinic,
        clinic_id: appointmentInfo.clinic_id,
        date: appointmentInfo.date,
        email: appointmentInfo.email,
        name: appointmentInfo.name,
        phone: appointmentInfo.phone,
        status: "canceled",
        timeslot: appointmentInfo.timeslot,
        timeslot_id: appointmentInfo.timeslot_id,
      }
    }

    axios.put(`${BASE_URL}${url}`, data)
      .then((response) => {
        if(response.status === 200){
          openSnackbar(
            "Appointment canceled successfully",
            "success",
            true
          );
          setTimeout(() => {
            confirmAction();
            window.location.reload();
          }, SNACKBAR_AUTO_HIDE_DURATION);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <DialogTitle id="alert-dialog-title">{"Cancel Appointment"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Do you want to cancel this appointment?"}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ color: PRIMARY_COLOR, boxShadow: "none" }}
          onClick={cancelAppointment}
        >
          YES
        </Button>
        <Button
          sx={{ color: PRIMARY_COLOR, boxShadow: "none" }}
          onClick={handleClose}
          autoFocus
        >
          NO
        </Button>
      </DialogActions>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(CancelPopup);
