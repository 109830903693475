import React from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import deleteIcon from "../images/delete.svg";
import editIcon from "../images/edit.svg";
import eyeIcon from "../images/eyeIcon.svg";
import Design from "./ExternalLinks.module.css";
import { SHOW_POPUP } from "../store/actions/popUpActions";
import { NavLink } from "react-router-dom";

function ExternalLink(props) {
  const { link } = props;

  const confirmDeleteExternalLink = () => {
    props.onOpenPopup(true, false, link.id, "delete");
  };

  const editExternalLink = () => {
    props.onOpenPopup(true, false, link.id, "edit_link");
  };

  return (
    <div className={Design.externalLinks}>
      <Card className={Design.externalLinksCard}>
        <div>
          <h3>{link.title}</h3>
        </div>
      </Card>

      <div className={Design.externalLinksActions}>
        <div className={Design.actionbtnContainer}>
          <div className={Design.btnContainer}>
            <button className={Design.btn} onClick={confirmDeleteExternalLink}>
              <img src={deleteIcon} width={20} alt="edit" />
            </button>
            <button className={Design.btn} onClick={editExternalLink}>
              <img src={editIcon} width={20} alt="delete" />
            </button>
            <NavLink to={link.url}>
              <button className={Design.btn}>
                <img src={eyeIcon} width={20} alt="view" />
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenPopup: (showPopup, confirmAction, userId, action) =>
      dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action }),
  };
};

export default connect(null, mapDispatchToProps)(ExternalLink);
