import React, {useState} from "react";
import axios from "axios";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header";
import TextEditorContainer from "../../components/TextEditorContainer";
import PrimaryBtn from "../../components/PrimaryBtn";
import BannerImage from "../../components/BannerImage";
import Design from "../../blogs/createBlog/CreateBlog.module.css";
import { BASE_URL } from "../../utils/constants/applicationConstants";
import { SHOW_TOAST } from "../../store/actions/toastAction";
import { SNACKBAR_AUTO_HIDE_DURATION } from "../../utils/constants/constants";

function CreatePatientLeaflets(props) {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState();
  const [content, setContent] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const onHandleValidation = () => {

    if(!image){
      props.openSnackbar("Please attach an image", "error", true);
      setLoading(false);
      return false;
    }else if(!title){
      props.openSnackbar("Please enter title", "error", true);
      setLoading(false);
      return false;
    }else if(!author){
      props.openSnackbar("Please enter author", "error", true);
      setLoading(false);
      return false;
    }else if(!category){
      props.openSnackbar("Please enter category", "error", true);
      setLoading(false);
      return false;
    }else if(!description){
      props.openSnackbar("Please enter description", "error", true);
      setLoading(false);
      return false;
    }else if(!content || content === "<p><br></p>" || content === "<p></p>" || content === "<p><br/></p>" || content === "<p>&nbsp;</p>"){
      props.openSnackbar("Please enter content", "error", true);
      setLoading(false);
      return false;
    } 
    return true;

  };

  const onCreateLeaflet = () => {
    setLoading(true);
    if (onHandleValidation()) {
    axios
    .post(`${BASE_URL}/case/`, {
      author: author,
      category: category,
      desc: description,
      html: content,
      image: image,
      title: title,
    })
    .then((response) => {
      if (response.status === 200) {
        props.openSnackbar("Patient leaflet successfully added", "success", true);
        setLoading(false);
        setTimeout(() => {
          navigate("/leaflets");
        }, SNACKBAR_AUTO_HIDE_DURATION);
      }
    })
    .catch((error) => {
      setLoading(false);
      props.openSnackbar("Something went wrong", "error", true);
      console.log(error);
    });
  }
  };

  return (
    <div>
      <div className="headerSection">
        <Header title={"Create Patient Leaflets"} />
      </div>
      <div className="topMargin">
        <div className={Design.CreateBlogContent}>
          <h5>Banner Image</h5>
          <BannerImage onImageSelected={(value)=> setImage(value)}/>
          <h5>Title</h5>
          <div className="textInput">
            <input value={title} type="text" placeholder="Title" onChange={(e)=>{setTitle(e.target.value)}} />
          </div>
          <h5>Author</h5>
          <div className="textInput">
            <input value={author} type="text" placeholder="Author" onChange={(e)=>{setAuthor(e.target.value)}} />
          </div>
          <h5>Category</h5>
          <div className="textInput">
            <input value={category} type="text" placeholder="Category" onChange={(e)=>{setCategory(e.target.value)}} />
          </div>
          <h5>Description</h5>
          <div className={Design.textArea}>
            <textarea value={description} type="text" rows={10} placeholder="Description" onChange={(e)=>{setDescription(e.target.value)}} />
          </div>
          <h5>Content</h5>
          <div>
            <TextEditorContainer setBlog={(value)=>setContent(value)} />
          </div>
          <span onClick={onCreateLeaflet}>
            <PrimaryBtn title={"Add"} loading={loading}/>
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(CreatePatientLeaflets);