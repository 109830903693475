import React from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Card } from "antd";
import deleteIcon from "../images/delete.svg";
import editIcon from "../images/edit.svg";
import { SHOW_POPUP } from "../store/actions/popUpActions";
import { UserOutlined } from '@ant-design/icons';
import Design from "./VideoLectures.module.css";
import { extractVideoId } from "../utils/functions/convertors.js";

function VideoLecture(props) {
  const { videoLecture } = props;
  const { Meta } = Card;
  const navigate = useNavigate();

  const onDeleteVideoLecture = () => {
    props.onOpenPopup(true, false, videoLecture.id, "delete");
  }

  const onEditVideoLecture = () => {
    navigate(`/update/video/${videoLecture.id}`);
  }

  const videoId = extractVideoId(videoLecture.url);

  return (
    <div className={Design.card}>
    <Card
      style={{
        width: "100%",
      }}
      cover={
        <NavLink to={`/info/video/${videoLecture.id}`}>
          <img
           width={"100%"}
            alt="example"
            src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
          />
        </NavLink>
      }
      actions={[
        <img
          src={deleteIcon}
          alt="delete"
          key={"delete"}
          onClick={onDeleteVideoLecture}
        />,
        <img src={editIcon} alt="edit" key={"edit"} onClick={onEditVideoLecture} />,
      ]}
    >
      <NavLink to={`/info/video/${videoLecture.id}`}>
        <Meta
          title={videoLecture.title}
          description={videoLecture.desc}
        />
      </NavLink>
    </Card>
  </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenPopup: (showPopup, confirmAction, userId, action) =>
      dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action }),
  };
};

export default connect(null, mapDispatchToProps)(VideoLecture);