import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../utils/constants/applicationConstants";
import { formatDateToDDMMYY } from "../utils/functions/convertors";
import Design from "./ViewPatientLeaflet.module.css";
import LoadingScreen from "../components/LoadingScreen";
import { LOADING_SCREEN } from "../utils/constants/constants";

export default function ViewPatientLeaflet() {
  const { caseId } = useParams();
  const [caseStudy, setCaseStudy] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);

  useEffect(() => {
    const content = document.getElementById("content");

    axios
      .get(`${BASE_URL}/case/${caseId}`)
      .then((response) => {
        setCaseStudy(response.data.data);
        if (content) {
            content.innerHTML = response.data.data.html;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  setTimeout(() => {
    setLoadingScreen(false);
  }, LOADING_SCREEN);

  const date = formatDateToDDMMYY(caseStudy.updated);

  return (
    <div>
    {loadingScreen ? (<LoadingScreen />) : ( <div className={Design.marginBottom}>
      <img
        className={Design.bannerImage}
        src={caseStudy.image}
        alt="banner"
      ></img>
      <div className={Design.container}>
        <div className={Design.dateAndAuthor}>
          <span>
            {date.day} {date.shortMonthName}, {date.year}
          </span>
          <span className={Design.verticalLine}>|</span>
          <span>{caseStudy.author}</span>
        </div>
        <h1>{caseStudy.title}</h1>

        <div className={Design.shortDescription}>
            {caseStudy.desc}
        </div>
        <div id="content" className={Design.htmlText}>
        </div>
      </div>
    </div>)}
    </div>
  );
}
