import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import Header from "../components/Header";
import PrimaryBtn from "../components/PrimaryBtn";
import SecondaryBtn from "../components/SecondaryBtn";
import Design from "./clinicForm.module.css";
import { BASE_URL } from "../utils/constants/applicationConstants";
import { SHOW_TOAST } from "../store/actions/toastAction";
import { SNACKBAR_AUTO_HIDE_DURATION } from "../utils/constants/constants";

function UpdateClinicForm(props) {
  const navigate = useNavigate();
  const { clinicId } = useParams();
  const [clinicName, setClinicName] = useState();
  const [location, setLocation] = useState();
  const [contactInfo, setContactInfo] = useState();
  const [doctorName, setDoctorName] = useState();
  const [clinicAppointmentLink, setClinicAppointmentLink] = useState()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/clinic/${clinicId}`)
      .then((response) => {
        setClinicName(response.data?.data.name);
        setLocation(response.data?.data.location);
        setContactInfo(response.data?.data.contact);
        setDoctorName(response.data?.data.doctor);
        setClinicAppointmentLink(response.data.data.link)
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, []);

  const onSubmitAddClinicForm = () => {
    setLoading(true);
    axios
      .put(`${BASE_URL}/clinic/${clinicId}`, {
        name: clinicName,
        location: location,
        contact: contactInfo,
        link: clinicAppointmentLink,
        doctor: doctorName,
      })
      .then((response) => {
        if (response.status === 200) {
          props.openSnackbar("Clinic successfully updated", "success", true);
          setLoading(false);
          setTimeout(() => {
            navigate("/clinics");
          }, SNACKBAR_AUTO_HIDE_DURATION);
        }
      })
      .catch((error) => {
        props.openSnackbar("Something went wrong", "error", true);
        setLoading(false);
        console.log("error : ", error);
      });
  };

  return (
    <div>
      <Header title={"Update Clinic Info"} />
      <div className="topMargin">
        <div className={Design.UpdateClinicContainer}>
          <h5>Name of Clinic</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Name"
              value={clinicName}
              onChange={(e) => {
                setClinicName(e.target.value);
              }}
            />
          </div>
          <h5>Location</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            />
          </div>
          <h5>Contact</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Contact info"
              value={contactInfo}
              onChange={(e) => {
                setContactInfo(e.target.value);
              }}
            />
          </div>
          <h5>Doctor</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Doctor Name"
              value={doctorName}
              onChange={(e) => {
                setDoctorName(e.target.value);
              }}
            />
          </div>
          <h5>Clinic Appointment Link</h5>
          <div className="textInput">
            <input
              type="text"
              placeholder="Link"
              value={clinicAppointmentLink}
              onChange={(e) => {
                setClinicAppointmentLink(e.target.value);
              }}
            />
          </div>
          <div className={Design.actionButtonsContainer}>
          <span onClick={onSubmitAddClinicForm}>
            <PrimaryBtn title={"Save"} loading={loading} />
          </span>
          <NavLink to={`/add/clinic/time/${clinicId}`}>
            <SecondaryBtn title={"Add Working Days"} />
          </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(UpdateClinicForm);
