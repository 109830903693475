import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import PrimaryBtn from "../components/PrimaryBtn";
import Design from "./VideoLectures.module.css";
import { SHOW_TOAST } from "../store/actions/toastAction";
import axios from "axios";
import { BASE_URL } from "../utils/constants/applicationConstants";
import { SNACKBAR_AUTO_HIDE_DURATION } from "../utils/constants/constants";

function CreateVideoLecture(props) {
  const navigate = useNavigate();
  const [title, setTitle] = useState();
  const [category, setCategory] = useState();
  const [url, setUrl] = useState();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);

  const onHandleValidation = () => {
    if (!title) {
      props.openSnackbar("Please enter title", "error", true);
      setLoading(false)
      return false;
    } else if (!category) {
      props.openSnackbar("Please enter category", "error", true);
      setLoading(false)
      return false;
    } else if (!url) {
      props.openSnackbar("Please enter url", "error", true);
      setLoading(false)
      return false;
    } else if (!description) {
      props.openSnackbar("Please enter description", "error", true);
      setLoading(false)
      return false;
    }
    return true;
  };

  const onCreateVidoLecture = () => {
    setLoading(true);
    const data = {
      category: category,
      desc: description,
      title: title,
      url: url,
    };

    if (onHandleValidation()) {
      axios
        .post(`${BASE_URL}/video/`, data)
        .then((res) => {
          props.openSnackbar(
            "Video Lecture created successfully",
            "success",
            true
          );
          setLoading(false);
          setTimeout(() => {
            navigate("/videos");
          }, SNACKBAR_AUTO_HIDE_DURATION);
        })
        .catch((err) => {
          props.openSnackbar("Something went wrong", "error", true);
          setLoading(false);
          console.log("err: ", err);
        });
    }
  };


  return (
    <div>
      <div className="headerSection">
        <Header title={"Create Video Lecture"} />
      </div>
      <div className="topMargin">
        <div className={Design.createVideoLecture}>
          <h5>Title</h5>
          <div className="textInput">
            <input
              type="text"
              value={title}
              placeholder="Video Lecture title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <h5>Category</h5>
          <div className="textInput">
            <input
              type="text"
              value={category}
              placeholder="Category name"
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
          <h5>Upload Video link</h5>
          <div className="textInput">
          <input
              type="text"
              value={url}
              placeholder="Youtube video link"
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <h5>Description</h5>
          <div className="textInput">
            <input
              type="text"
              value={description}
              placeholder="Short description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <span onClick={onCreateVidoLecture}>
            <PrimaryBtn title={"Create"} loading={loading} />
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) =>
      dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(CreateVideoLecture);
