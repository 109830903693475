import React from 'react';
import emptyImage from "../images/emptyImage.svg"
import Design from "./VideoLectures.module.css";

export default function EmptyVideoLecture() {
  return (
    <div className={Design.emptyContainer}>
        <div className={Design.emptySection}>
            <img src={emptyImage} alt="emptyImage" />
            <h3>No Video Lectures</h3>
            <p>You haven't created any video lectures yet.</p>
        </div>
    </div>
  )
}
