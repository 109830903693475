import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Design from "./VideoLectures.module.css";
import axios from "axios";
import { BASE_URL } from "../utils/constants/applicationConstants";
import LoadingScreen from "../components/LoadingScreen";
import { LOADING_SCREEN } from "../utils/constants/constants";
import { extractVideoId } from "../utils/functions/convertors.js";

export default function ViewVideoLecture() {
  const { videoId } = useParams();
  const [videoLecture, setvideoLecture] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/video/${videoId}`)
      .then((res) => {
        setvideoLecture(res.data.data);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  setTimeout(() => {
    setLoadingScreen(false);
  }, LOADING_SCREEN);

  return (
    <div>
      {loadingScreen ? (
        <LoadingScreen />
      ) : (
        <div>
          <div className="headerSection">
            <Header title={"Video Lecture"} />
          </div>
          <div className="topMargin">
            <div className={Design.viewVideoLectureContainer}>
              <div className={Design.iframe}>
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${extractVideoId(
                    videoLecture.url
                  )}`}
                  allowFullScreen
                ></iframe>
              </div>
              <h5 className={Design.viewVideoLectureTitle}>
                {videoLecture.title}
              </h5>
              <div className={Design.viewVideoLectueCategory}>
                {videoLecture.category}
              </div>
              <p className={Design.viewVideoLectureDesc}>{videoLecture.desc}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
